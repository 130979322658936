const fixImageUrl = (image) => {
  let imageUrl = image

  if ( ! image.includes('http')) {
    imageUrl = `${process.env.IMAGE_URL}${image}`
  }

  return imageUrl
}

export default fixImageUrl
