import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {injectIntl} from 'react-intl';
import { OutboundLink } from 'react-ga';

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import arrowicon from '../../../assets/images/icons/arrow.svg'
import whitearrowicon from '../../../assets/images/icons/whitearrow.svg'

const OutlineOverride = css`
  color: #4d8dcd;
  background-color: #fff;

  &:hover,
  &:focus {
    background-color: #4d8dcd;
    border-color: #4d8dcd;
  }
`

const ArrowOverride = css`
  p {
    background: url(${arrowicon});
    background-position: right;
    background-repeat: no-repeat;
    padding: 0px;
    padding-right: 20px;
  }

  &:hover,
  &:focus {
    p {
      background-image: url(${whitearrowicon});
    }
  }
`

const RedButton = css`
  background-color: #C8122F;
  border-color: #C8122F;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #9A1127;
    border-color: #9A1127;
    color: #fff;
    box-shadow: 0 0 0 3px rgba(200,18,47,0.5);
  }
`

const DefaultStyle = props => css`
  ${tw`outline-none font-montserrat text-sm text-center no-underline cursor-pointer`}
  padding: 15px 30px;
  border: 1px solid #4d8dcd;
  background-color: ${props.outline ? '#fff' : '#4d8dcd'};
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  line-height: 1.258;
  width: ${props.width ? props.width : 'auto'};
  color: ${props.outline ? '#4d8dcd' : '#fff'};
  border-radius: 9999px;
  display: inline-block;

  p {
    margin: 0px;
  }

  &:hover,
  &:focus {
    background-color: ${props.outline ? '#4d8dcd' : '#6db3f8'};
    border-color: ${props.outline ? '#4d8dcd' : '#6db3f8'};
    color: #fff;
    ${tw`shadow-outline`}
  }

  ${props.modification.includes('outline') ? OutlineOverride : null}
  ${props.modification.includes('arrow') ? ArrowOverride : null}
  ${props.modification.includes('red') ? RedButton : null}
`

const InternalLinkStyle = styled(Link)`
  ${DefaultStyle}
`

const LinkStyle = styled(OutboundLink)`
  ${DefaultStyle}
`

const ButtonStyle = styled.button`
  ${DefaultStyle}
`

const Button = (props) => {
  const {
    to,
    children,
    html,
    convert,
    href,
    intl,
    // eslint-disable-next-line
    modification
  } = props;
  let external = /(http(s?)):\/\//gi.test(to);
  if (href) {
    external = /(http(s?)):\/\//gi.test(href);
  }
  let ButtonEle;
  const alteredProps = Object.assign({}, props)

  if (to) {
    if (external) {
      ButtonEle = LinkStyle
      alteredProps.target = '_blank'
      alteredProps.rel = 'noopener noreferrer'
      alteredProps.eventLabel = alteredProps.label || alteredProps.to
    } else {
      ButtonEle = InternalLinkStyle
      if (convert) {
        alteredProps.to = fixInternalLinks(to, intl.locale)
      } else {
        alteredProps.to = to
      }
    }
  } else {
    ButtonEle = ButtonStyle
  }

  if (!to && href) {
    ButtonEle = LinkStyle
    alteredProps.eventLabel = alteredProps.label || alteredProps.to
    alteredProps.to = href
  }

  if (html) {
    return <ButtonEle {...alteredProps} dangerouslySetInnerHTML={{__html:html}} />
  }

  return <ButtonEle {...alteredProps}>{children}</ButtonEle>
}

Button.propTypes = {
  // Either blue or outlined
  outline: PropTypes.bool,
  // Link
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  // HTML to render
  html: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  convert: PropTypes.bool,
  modification: PropTypes.string
}

Button.defaultProps = {
  outline: false,
  children: null,
  to: null,
  html: '',
  convert: true,
  modification: ''
}

export default injectIntl(Button)
