import styled from '@emotion/styled'

const IsMobile =  styled.div`
  display: none;
  @media screen and (max-width:919px) {
    display: block;
    width: 100%;
  }
`

export default IsMobile
