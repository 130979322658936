/* eslint-disable */
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ScrollLock from 'react-scrolllock';
import {Link} from 'gatsby';

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import sendEvent from '../../../helpers/sendAnalytics'

import LanguageSwitcherMobile from '../LanguageSwitcherMobile'

// Contest promo Images
import contestLogo from '../../../assets/images/gear_small.png';
import contestLogoFr from '../../../assets/images/small_clogo_fr.png';

import Badge from '../../../assets/images/promotions/badge-en.png';
import BadgeFR from '../../../assets/images/promotions/badge-fr.png';

import socialgrayfb from '../../../assets/images/fb-gray.svg';
import socialgrayinsta from '../../../assets/images/insta-gray.svg';

const MobileMenuButton = styled.button`
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  line-height: 0;

  &.is-active {
    position: relative;
    z-index: 700;
  }

  &:hover {
    opacity: 0.7; }
  &.is-active:hover {
    opacity: 0.7; }
  &.is-active .hamburger-inner,
  &.is-active .hamburger-inner::before,
  &.is-active .hamburger-inner::after {
    ${tw`bg-grey`}
  }

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    ${props => props.type === 'float' ? tw`bg-border-blue` : tw`bg-grey`}
    width: 30px;
    height: 3px;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

  .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

  &.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  &.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  &.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }
`

const MobileMenuContainer = styled.div`
  ${tw`fixed pin bg-white w-screen h-screen`}
  display: ${props => (props.open) ? 'block' : 'none'};
  z-index: 600;
`

const SocialWrapper = styled.div`
  ${tw`flex flex-row mb-5`}
`

const SocialIcon = styled.a`
  font-size: 14px;
  line-height: 1;
  ${tw`mr-5 p-4 -ml-4`}

  img {
    border: 0px;
    width: auto;
    margin: 0 auto;
  }
`

const MenuUL = styled.ul`
  ${tw`list-reset m-0 p-0`}

  @media screen and (max-width: 64em) {
    margin-top: 100px;
  }

  @media screen and (max-width: 40em) {
    margin-top: auto;
  }
`

const MobileTop = styled.div`
  ${tw`bg-light-blue flex pl-5 flex-col`}
  height: 60vh;
`

const MobileFooter = styled.div`
  ${tw`flex`}
  height: 40vh;

  div.languageSwitcher {
    & > a {
      ${tw`pl-5 pt-5 block text-2xl font-light`}
    }
  }
`

const PromoWrapper = styled.div`
  margin-left: auto;

  img {
    max-width: 200px;
  }

  @media screen and (max-width: 64em) {
    img {
      max-width: 140px;
    }
  }
`

const MenuLink = styled(Link)`
  ${tw`block no-underline text-black font-montserrat text-3xl text-black leading-normal py-2`}
`

const MenuLinkExternal = styled.a`
  ${tw`block no-underline text-black font-montserrat text-3xl text-black leading-normal py-2`}
`

const MenuLIFR = css`
  ${MenuLink},
  ${MenuLinkExternal} {
    ${tw`text-2xl`}
  }
`

const MenuLI = styled.li`
  ${tw`m-0 p-0`}

  ${props => props.locale === 'fr' ? MenuLIFR : null }
`

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  render() {
    const { open } = this.state;
    const {
      menuItems,
      urls,
      locale,
      type
    } = this.props;
    return (
      <React.Fragment>
        <MobileMenuButton
          tabIndex="0"
          aria-label="Menu"
          role="button"
          aria-controls="navigation"
          onClick={this.toggleMenu}
          className={(open) ? 'is-active' : ''}
          type={type}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
        </MobileMenuButton>

        {open ? <ScrollLock /> : null}

        <MobileMenuContainer open={open}>
          <MobileTop>
            <MenuUL>
              {menuItems.map(item => (
                <MenuLI locale={locale} key={item.to}>
                  {item.internal ?
                    (
                      <MenuLink to={fixInternalLinks(item.to, locale)}>
                        {item.name}
                      </MenuLink>
                    )
                  :
                    (
                      <MenuLinkExternal href={item.to} target={item.target} rel="noopener noreferrer">
                        {item.name}
                      </MenuLinkExternal>
                    )
                  }
                </MenuLI>
              ))}
            </MenuUL>

            <SocialWrapper>
              <SocialIcon href="https://www.facebook.com/RoyaleKittensChatons" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', label: (locale === 'en') ? 'Facebook_Click' : 'Facebook_Click_FR'}})}>
                <img src={socialgrayfb} alt="Facebook" />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/theroyalekittens/" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', label: (locale === 'en') ? 'Instagram_Click' : 'Instagram_Click_FR'}})}>
                <img src={socialgrayinsta} alt="Instagram" />
              </SocialIcon>
            </SocialWrapper>
          </MobileTop>

          <MobileFooter>
            <div className="languageSwitcher">
              <LanguageSwitcherMobile urls={urls} locale={locale} />
            </div>
            {(locale === 'zh-hant' || locale === 'zh-hans') ? null : (
              <PromoWrapper>
                <a href={(locale === 'en') ? 'http://www.smartsource.ca/smartsource2/static_content/app/#/index.html?Link=V7JBJRKNLNCOI' : 'http://www.smartsource.ca/smartsource2/static_content/app/#/index.html?Link=V7JBJRKNLNCOI&locale=fr_CA'} target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Coupon_Click', action: 'Click', Label: (locale === 'en') ? 'Menu_Coupon_Masterband' : 'Menu_Coupon_Masterband_FR'}})}>
                  <img src={(locale === 'en') ? Badge : BadgeFR} alt="Coupon" />
                </a>
              </PromoWrapper>
            )}
          </MobileFooter>
        </MobileMenuContainer>
      </React.Fragment>
    )
  }
}

MobileMenu.propTypes = {}

MobileMenu.defaultProps = {}

export default MobileMenu
