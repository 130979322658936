import React from 'react'
import PropTypes from 'prop-types'

import Container from '../../common/Container'

import {
  ProductCategoriesBlock,
  ImageLeftTextRightBlock,
  ImageRightTextLeftBlock,
  ProductLandingBlock,
  ProductCategoryBlock,
  AlsoBoughtBlock,
  ProductLocatorBlock,
  TextBlock,
  PromotionBlock,
  VideoBlock,
  ImageBlock,
  GalleryBlock,
  ThreeColumnWithIconsBlock
} from '..'

const RenderBlock = (props) => {
  const { blocks, page } = props;

  return (
    <React.Fragment>
      {
        (blocks) ? blocks.map((block) => {
          const modification = (block.modification) ? block.modification : ''
          // eslint-disable-next-line dot-notation
          switch (block['__typename']) {
            case 'ProductCategoriesBlock':
              return (
                <Container>
                  <ProductCategoriesBlock
                    modification={modification}
                    title={block.title}
                    title_html={block.title_html}
                    products={[
                      block.product_1,
                      block.product_2,
                      block.product_3,
                      block.product_4
                    ]}
                    key={block.id}
                  />
                </Container>
              )
            case 'ImageLeftTextRightBlock':
              return (
                <ImageLeftTextRightBlock
                  modification={block.modification}
                  alt=''
                  image={block.image_url}
                  right={block.right}
                  key={block.id}
                />
              )
            case 'ImageRightTextLeftBlock':
              return (
                <ImageRightTextLeftBlock
                  modification={block.modification}
                  alt=''
                  image={block.image_url}
                  left={block.left}
                  key={block.id}
                />
              )
            case 'PromotionBlock':
              return (
                <PromotionBlock
                  modification={block.modification}
                  alt=''
                  image_url={block.image_url}
                  right={block.right}
                  key={block.id}
                />
              )
            case 'ProductLandingBlock':
              return (
                <Container key={block.id}>
                  <ProductLandingBlock
                    modification={block.modification}
                    title={block.title}
                    image={block.image_url}
                    miniImage={block.mini_image_url}
                    buttonName={block.button_name}
                    buttonLink={block.button_link}
                    description={block.description}
                  />
                </Container>
              )
            case 'ProductCategoryBlock':
              return (
                <ProductCategoryBlock
                  guid={block.guid}
                  modification={block.modification}
                  title={block.title}
                  description={block.description}
                  video={block.video}
                  product_header={block.product_header}
                  logo_image={block.logo_image_url}
                  product_category_image={block.product_category_image_url}
                  products={block.products}
                  key={block.id}
                />
              )
            case 'ProductLocatorBlock':
              return (
                <ProductLocatorBlock
                  title={block.title_html}
                  upc={block.upc}
                  modification={block.modification}
                  key={block.id}
                  page={page}
                />
              )
            case 'AlsoBoughtBlock':
              return (
                <Container key={block.id}>
                  <AlsoBoughtBlock
                    modification={block.modification}
                    title={block.title}
                    products={block.products}
                  />
                </Container>
                )
            case 'TextBlock':
              return (
                <TextBlock
                  modification={block.modification}
                  text={block.text}
                  key={block.id}
                />
              )
            case 'VideoBlock':
              if (block.url) {
                return (
                  <VideoBlock
                    modification={block.modification}
                    url={block.url}
                    key={block.id}
                  />
                )
              }
              return null
            case 'ImageBlock':
              return (
                <Container key={block.id}>
                  <ImageBlock
                    modification={block.modification}
                    image={block.image_url}
                  />
                </Container>
              )
            case 'GalleryBlock':
              return (
                <GalleryBlock
                  modification={block.modification}
                  images={block.images_url}
                  title={block.title_html}
                  key={block.id}
                />
            )
            case 'ThreeColumnWithIconsBlock':
              return (
                <ThreeColumnWithIconsBlock
                  modification={block.modification}
                  icon_1={block.icon_1_url}
                  icon_2={block.icon_2_url}
                  icon_3={block.icon_3_url}
                  content_1={block.content_1}
                  content_2={block.content_2}
                  content_3={block.content_3}
                  key={block.id}
                />
              )
          default:
    // eslint-disable-next-line dot-notation
              console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
          }

          return null
        }) : null
      }
    </React.Fragment>
  )
}

RenderBlock.propTypes = {
  // eslint-disable-next-line
  blocks: PropTypes.array.isRequired
}

RenderBlock.defaultProps = {}

export default RenderBlock
