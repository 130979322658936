import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Flex, Box } from '../../common/Grid'
import Container from '../../common/Container'

import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock
} from '..'

const SustabilityStyles = css`
  .gatsby-image-wrapper {
    max-width: 540px;
    margin-left: auto;
  }
`

const ImageRightTextLeftWrapper = styled.div`
  ${props => (props.modification.includes('bluebg')) ? tw`bg-light-blue pt-8 pb-6 -mt-12` : tw`bg-none`}
  ${props => (props.modification.includes('fullimage')) ? 'overflow:hidden;' : ''}
  ${props => (props.modification.includes('sustainability')) ? SustabilityStyles : null }
  ${props => (props.modification.includes('morepadding')) ? tw`pt-12 pb-16` : ''}
  ${tw`mb-20`}

  :lang(zh-hans) .gatsby-image-wrapper,
  :lang(zh-hant) .gatsby-image-wrapper {
    width: 375px;
  }

  :lang(zh-hans) .image,
  :lang(zh-hant) .image {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  :lang(zh-hans) h2,
  :lang(zh-hant) h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  @media screen and (max-width: 64em) {
    :lang(zh-hans) .gatsby-image-wrapper,
    :lang(zh-hant) .gatsby-image-wrapper {
      margin-bottom: 64px;
    }
  }
`

const ImageRightTextLeftFigure = styled.figure`
  margin: 0;
  img {
    width: 100%;
  }
  figcaption {
    ${tw`font-raleway pt-2 text-xs`}
    color: #4F5354;
  }
`

const ImageRightTextLeft = (props) => {
  const { modification, image, alt, left, caption } = props;

  let paddingLeft = 0;
  let imageWidth = 620;
  if (modification.includes('fullimage')) {
    paddingLeft = [0,0,0,"20%"];
    imageWidth = 920;
  }

  return (
    <ImageRightTextLeftWrapper modification={modification}>
      <Container modification={modification} width={(modification.includes('fullimage')) ? '100%': "1240px"}>
        <Flex alignItems="center" flexWrap="wrap" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'row']} mx={[0, 0, 0, -2]}>
          <Box width={[1,1,1,1/2]} px={[0,0,0,2]} pl={paddingLeft}>
            {left.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock modification={block.modification} title={block.title} link={block.link} key={block.guid} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>

          <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[5,5,5,0]} className="image">
            { image ?
              (
                <ImageRightTextLeftFigure>
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    data-imageboss-src={image}
                    alt={striptags(alt)}
                    data-imageboss-width={imageWidth}
                    loading="lazy"
                  />
                  {caption !== '' ? (<figcaption>{caption}</figcaption>) : null }
                </ImageRightTextLeftFigure>
              )
              : null }
          </Box>
        </Flex>
      </Container>
    </ImageRightTextLeftWrapper>
  )
}

ImageRightTextLeft.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  left: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired,
  caption: PropTypes.string
}

ImageRightTextLeft.defaultProps = {
  modification: '',
  caption: ''
}

export const imageRightTextLeftBlockFragment = graphql`
  fragment imageRightTextLeftBlockFragment on ImageRightTextLeftBlock {
    id
    modification
    image_url
    left {
      __typename

      ... on TextBlock {
        guid
        modification
        text
      }

      ... on LinkBlock {
        guid
        title
        modification
        link
      }

      ... on HeaderBlock {
        guid
        modification
        type
        title
      }

      ... on ImageBlock {
        guid
        modification
        image_url
      }
    }
  }
`

export default ImageRightTextLeft
