import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex, Box } from '../../common/Grid'

import Container from '../../common/Container'

import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock
} from '..'

const PantheraStyles = css`
  max-width: 300px;
  margin-top: -75px;
  margin-left: auto;
`

const HomepageTipsStyles = css`
  max-width: 450px;
  margin: 0 auto;

  @media screen and (min-width: 40em) {
    margin: 0px 0px 20px;
  }

  @media screen and (max-width: 64em) {
    max-width: 100%;
  }
`

const AboutUsVideoStyles = css`
  ${tw`items-center`}

  p.p1 {
    ${tw`pl-10`}
  }

  a {
    ${tw`ml-10`}
  }

  @media screen and (max-width: 64em) {
    p {
      ${tw`pl-0`}
    }
  }
`

const ImageLeft = styled.img`
  ${props => (props.modification.includes('panthera')) ? PantheraStyles : null}
  ${props => (props.modification.includes('homepagetips')) ? HomepageTipsStyles : null}
  ${props => (props.modification.includes('img-width-375')) ? 'width: 375px; margin-left: 60px;' : 'margin-right: 40px;'}

  @media screen and (max-width: 991px) {
    ${props => (props.modification.includes('img-width-375')) ? 'width: auto;margin-left: 0;' : 'margin-right: 0;'}
    width: 100%;
  }
`

const ImageLeftTextRightWrapper = styled.div`
  ${tw`mb-20`}
  ${props => (props.modification.includes('biancablue')) ? tw`bg-light-blue pt-24 pb-24` : tw`bg-none`}

  :lang(zh-hans) h2,
  :lang(zh-hant) h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
`

const ImageLeftTextRightFlex = styled(Flex)`
  ${props => (props.modification.includes('aboutusvideo')) ? AboutUsVideoStyles : null}
  ${tw`flex-wrap`}
`

const ImageLeftTextRightBlock = (props) => {
  const { modification, image, alt, right } = props;

  let SidePadding = 2;
  if (modification.includes('wider')) {
    SidePadding = 5;
  }

  let alignItems = 'stretch';
  if (modification.includes('center')) {
    alignItems = 'center';
  }

  return (
    <ImageLeftTextRightWrapper modification={modification}>
      <Container>
        <ImageLeftTextRightFlex modification={modification} alignItems={alignItems} flexWrap="wrap" flexDirection={['column', 'column', 'column', 'row']}>
          <Box
            width={[
              1,
              1,
              1,
              1 / 2
            ]}
            px={[
              0,
              0,
              SidePadding
            ]}
            mb={[
              5,
              5,
              5,
              0
            ]}
            className="image"
          >
            { image ?
              (
                <ImageLeft
                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  data-imageboss-src={image}
                  alt={striptags(alt)}
                  width="620"
                  loading="lazy"
                  modification={modification}
                />
            )
            : null }
          </Box>

          <Box
            width={[
              1,
              1,
              1,
              1 / 2
            ]}
            px={[
              0,
              0,
              0,
              SidePadding
            ]}
          >

            {right.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock backgroundColor={block.backgroundColor} modification={block.modification} title={block.title} link={block.link} key={block.guid} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>
        </ImageLeftTextRightFlex>
      </Container>
    </ImageLeftTextRightWrapper>
  )
}

ImageLeftTextRightBlock.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  right: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired
}

ImageLeftTextRightBlock.defaultProps = {
  modification: ''
}

export const imageLeftTextRightBlockFragment = graphql`
  fragment imageLeftTextRightBlockFragment on ImageLeftTextRightBlock {
    id
    modification
    image_url
    right {
      __typename

      ... on TextBlock {
        guid
        modification
        text
      }

      ... on LinkBlock {
        guid
        title
        modification
        link
      }

      ... on HeaderBlock {
        guid
        modification
        type
        title
      }

      ... on ImageBlock {
        guid
        modification
        image_url
      }
    }
  }
`

export default ImageLeftTextRightBlock
