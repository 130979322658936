import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';

import Icon from '../Icon'

const LanguageSwitcherStyle = styled.div`
  ${tw`border-b border-solid border-transparent font-montserrat font-semibold text-black text-sm leading-tight no-underline mr-5`}

  > div > span {
    ${tw`flex items-center cursor-pointer outline-none focus:shadow-outline`}
  }
`

const LanguageSwitcherIcon = styled.div`
  width: 30px;
  height: 24px;
  margin-right: 10px;
`

const LanguageSwitcherDownIcon = styled.div`
  margin-left: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;

  &.open {
    transform: rotate(180deg);
  }
`

const MenuWrapper = styled(Menu)`
  position: absolute;
  width: 120px;
  ${tw`bg-white p-4 font-medium text-center`}

  ul {
    ${tw`list-reset m-0`}

    li {
      ${tw`mb-2 block cursor-pointer outline-none`}

      > div {
        ${tw`outline-none inline border-0 border-b border-solid border-transparent`}
        -webkit-transition: border 200ms ease;
        transition: border 200ms ease;

        a {
          ${tw`no-underline text-black`}
        }

        &:hover,
        &:focus {
          ${tw`border-black`}
        }
      }

      &:nth-child(4) {
        ${tw`mb-0`}
      }

      &:hover,
      &:focus {
        > div {
          ${tw`border-black`}
        }
      }
    }
  }
`

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.locales = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-hant',
        label: '繁體中文'
      },
      {
        value: 'zh-hans',
        label: '简体中文'
      }
    ]
  }

  render() {
    const { urls, locale } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    const openClass = this.state.isOpen ? 'open' : '';

    return (
      <LanguageSwitcherStyle>
        <Wrapper
          onSelection={null}
          onMenuToggle={({ isOpen }) => { this.setState({ isOpen })}}
        >
          <Button>
            <LanguageSwitcherIcon>
              <Icon icon="language" width="30" />
            </LanguageSwitcherIcon>
            { locale === 'en' ? "English" : null }
            { locale === 'fr' ? "Français" : null }
            { locale === 'zh-hant' ? "繁體中文" : null }
            { locale === 'zh-hans' ? "简体中文" : null }
            <LanguageSwitcherDownIcon className={`${openClass}`}>
              <Icon icon="chevron" down size={12} strokeWidth={2} width="12" />
            </LanguageSwitcherDownIcon>
          </Button>

          <MenuWrapper>
            <ul>
              {
                this.locales.map((item) => (
                  <li key={item.value}>
                    {item.value && urls && urls[item.value.replace('-','_')] ? (
                      <MenuItem value={item.value} text={item.label}>
                        <a href={urls[item.value.replace('-','_')]} alt={item.label}>
                          {item.label}
                        </a>
                      </MenuItem>
                    ) : null}
                  </li>
                ))
              }
            </ul>
          </MenuWrapper>
        </Wrapper>
      </LanguageSwitcherStyle>
    )
  }
}

LanguageSwitcher.propTypes = {
  // URL to change too
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }).isRequired,
  // Which locale
  locale: PropTypes.string.isRequired
}

export default LanguageSwitcher
