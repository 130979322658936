/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {injectIntl} from 'react-intl';
import { Background } from "react-imgix";

import Container from '../../common/Container'
import { Flex, Box } from '../../common/Grid'

 import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock
} from '..'

const TempContestBlock = styled.div`
  width: 100%;
  display: block;
  position: relative;
`

const PromoImg = styled.img`
  width: 100%;
  @media(max-width: 769px){
    display: none;
  }
`
const PromoImgMobile = styled.img`
  width: 100%;
  display: none;
  @media(max-width: 769px){
    display: block;
  }
`

const BlueButton = styled.button`
  cursor: pointer;
  background-image: linear-gradient(-134deg, #2DB2F6 0%, #0552BE 100%);
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  font-weight: 300;
  border: 0;
  padding: 12px 22px;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 70%;
  left: 74%;
  font-family: Montserrat, sans-serif;
  font-weight: 500;

  @media(max-width: 769px){
    top: auto;
    left: auto;
    right: auto;
    width: 75%;
    margin: 0 12.5%;
    bottom: 7%;
    padding: 12px 22px;
  }
`


const PromotionRightWrapper = styled.div`
  ${tw`relative`}
`

const GufsStylesEN = css`
  ${tw`p-10 h-full`}
  background: #FFFBF4;
  ${PromotionRightWrapper} img {
    max-width: 200px;
    ${tw`mb-16`}
  }

  p {
    ${tw`font-medium font-montserrat text-lg m-0 leading-tight`}
    color: #51586E;

    .gufs-dg {
      color: #006838;
      font-size: 70px;
      ${tw`font-semibold font-montserrat tracking-tight leading-none`}

      sup {
        font-size: 16px;
        top: -38px;
        letter-spacing: normal;
      }
    }

    .gufs-lg {
      font-size: 38px;
      color: #A6CE39;
      ${tw`font-semibold font-montserrat leading-none`}
    }
  }

  a {
    border: 0px;
    background-image: linear-gradient(134deg, #C7DC56 0%, #006838 100%);

    p {
      color: #fff;
    }

    &:focus,
    &:hover {
      border: 0px;
      box-shadow: 0 0 0 3px rgba(0,104,56,0.5);
    }
  }

  @media(max-width: 1024px) {
    ${tw`p-5 text-center`}

    img {
      margin: 0 auto !important;
    }

    p {
      .gufs-dg {
        font-size: 60px;

        sup {
          top: -29px;
        }
      }

      .gufs-lg {
        font-size: 21px;
        margin-top: 10px;
      }
    }

    div {
      margin: 0px 0px 15px 0px;
    }
  }
`

const GufsStylesFR = css`
  ${tw`p-10 h-full`}
  background: #FFFBF4;
  ${PromotionRightWrapper} img {
    max-width: 200px;
    ${tw`mb-16`}
  }

  p {
    ${tw`font-medium font-montserrat text-lg m-0 leading-tight`}
    color: #51586E;

    .gufs-dg {
      color: #006838;
      font-size: 75px;
      ${tw`font-semibold font-montserrat tracking-tight leading-none`}

      sup {
        font-size: 11px;
        top: -43px;
        letter-spacing: normal;
      }
    }

    .gufs-lg {
      font-size: 21px;
      color: #A6CE39;
      ${tw`font-semibold font-montserrat leading-none`}
    }
  }

  a {
    border: 0px;
    background-image: linear-gradient(134deg, #C7DC56 0%, #006838 100%);

    p {
      color: #fff;
    }

    &:focus,
    &:hover {
      border: 0px;
      box-shadow: 0 0 0 3px rgba(0,104,56,0.5);
    }
  }

  @media(max-width: 1024px) {
    ${tw`p-5 text-center`}

    img {
      margin: 0 auto !important;
    }

    p {
      .gufs-dg {
        font-size: 45px;

        sup {
          top: -24px;
        }
      }

      .gufs-lg {
        font-size: 21px;
      }
    }

    div {
      margin: 0px 0px 15px 0px;
    }
  }
`

const CouponStyles = css`
  ${tw`p-10 h-full bg-border-blue`}
  ${PromotionRightWrapper} img {
    max-width: 400px;
    ${tw`mb-16`}
  }

  h2 {
    ${tw`m-0 my-4 text-white text-6xl leading-none font-montserrat font-light`}

    @media (max-width: 800px) {
      ${tw`text-4xl`}
    }

    p {
      margin: 0px;
    }

    strong {
      ${tw`font-bold`}
    }
  }

  p {
    ${tw`text-white font-medium font-montserrat text-4xl m-0 leading-tight`}
    max-width: 400px;
  }

  a {
    background-color: #ffffff;
    border-color: #ffffff;

    p {
      ${tw`text-base text-border-blue`}
    }

    &:focus,
    &:hover {
      background-color: #ffffff;
      border-color: #ffffff;
      box-shadow: 0 0 0 3px rgba(255,255,255,0.5);
    }
  }

  @media(max-width: 769px){
    ${PromotionRightWrapper} img {
      max-width: 100%;
    }
  }
`

const PromotionBlockWrapper = styled.div`
  ${tw`mb-20`}

  @media screen and (min-width: 40em) {
    ${tw`mb-20`}
  }
`

const PromotionStyles = styled.div`
  ${tw`relative`}
  ${props => (props.modification.includes('gufs') && props.locale === 'en') ? GufsStylesEN : null}
  ${props => (props.modification.includes('gufs') && props.locale === 'fr') ? GufsStylesFR : null}
  ${props => (props.modification.includes('coupon')) ? CouponStyles : null}
`

const PromotionImg = styled.div`
  height: 100%;

  & > div {
    height: 100%;

    @media (max-width: 1280px) {
      height: 100%;
    }

    @media (max-width: 800px) {
      height: 400px;
    }

    @media (max-width: 600px) {
      height: 250px;
    }
  }
`

const PromotionFlexWrapper = styled(Flex)`
  box-shadow: 0px 20px 40px rgba(0,0,0,0.3);
`

const PromotionBlock = (props) => {
  const { modification, image_url, alt, right, intl } = props;
  return (
    <PromotionBlockWrapper>
      <Container width={1400}>
        <PromotionFlexWrapper flexWrap="wrap">
          <Box
            width={[
              1,
              1,
              1 / 2,
              1 / 2
            ]}
          >
            <PromotionImg>
              <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={image_url} alt={alt} loading="lazy" />
            </PromotionImg>
          </Box>

          <Box
            width={[
              1,
              1,
              1 / 2,
              1 / 2
            ]}
            modification={modification}
          >
            <PromotionStyles modification={modification} locale={intl.locale}>
              <PromotionRightWrapper>
                {right.map((block) => {
                  // eslint-disable-next-line dot-notation
                  switch (block['__typename']) {
                    case 'TextBlock':
                      return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                    case 'LinkBlock':
                      return <LinkBlock modification={block.modification} title={block.title} link={block.link} key={block.guid} appnexus="https://secure.adnxs.com/px?id=1067010&seg=16534037&t=2" ga={{category:'Coupon_Click',action:'Click',label:(intl.locale === 'en') ? 'Homepage_Coupon_Masterband' : 'Homepage_Coupon_Masterband_FR'}} />
                    case 'HeaderBlock':
                      return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                    case 'ImageBlock':
                      return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} />
                    default:
                      // eslint-disable-next-line dot-notation
                      console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
                  }

                  return null
                })}
              </PromotionRightWrapper>
            </PromotionStyles>
          </Box>

        </PromotionFlexWrapper>
      </Container>
    </PromotionBlockWrapper>
  )
}

PromotionBlock.propTypes = {
  modification: PropTypes.string,
  image_url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  right: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired
}

PromotionBlock.defaultProps = {
  modification: ''
}

export const PromotionBlockFragment = graphql`
  fragment promotionBlockFragment on PromotionBlock {
    id
    modification
    image_url
    right {
      __typename

      ... on TextBlock {
        guid
        modification
        text
      }

      ... on LinkBlock {
        guid
        title
        modification
        link
      }

      ... on HeaderBlock {
        guid
        modification
        type
        title
      }

      ... on ImageBlock {
        guid
        modification
        image_url
      }
    }
  }
`

export default injectIntl(PromotionBlock)
