/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {injectIntl} from 'react-intl';

import MenuItem from '../../common/MenuItem'
import MobileMenu from '../../common/MobileMenu'
import LanguageSwitcher from '../../common/LanguageSwitcher'
import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'

import sendEvent from '../../../helpers/sendAnalytics'

import logo from '../../../assets/images/logo.png';


// White Images
import socialfb from '../../../assets/images/fb-white.svg';
import socialinsta from '../../../assets/images/insta-white.svg';

// Contest promo Images
import contestLogo from '../../../assets/images/gear_small.png';
import contestLogoFr from '../../../assets/images/small_clogo_fr.png';

// Gray Images
import socialgrayfb from '../../../assets/images/fb-gray.svg';
import socialgrayinsta from '../../../assets/images/insta-gray.svg';

import Badge from '../../../assets/images/promotions/badge-en.png';
import BadgeFR from '../../../assets/images/promotions/badge-fr.png';

const Container = styled.header`
  ${props => (props.front) ? tw`min-h-screen` : tw``}
`

const FloatMenu = styled.div`
  ${tw`pin-x pin-t absolute`}
  height: 120px;
  z-index: 2;
`

const DefaultMenu = styled.div`
  height: 120px;
  position: relative;
  z-index: 2;
`

const LogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-left: 50px;
  max-width: 160px;
  margin-top: 10px;
  img {
    width: 160px;
  }

  @media (max-width: 1024px) {
    margin: 0px;
    max-width: 100px;

    img {
      width: 100px;
    }
  }
`

const RightMenu = styled.div`
  ${tw`flex items-center`}
  width: 80%;
`

const Menu = styled.nav`
  ${tw`flex flex-row justify-between items-center`}
`

const MenuUL = styled.ul`
  ${tw`flex items-center justify-start list-reset`}
  height: 35px;
  margin-left: auto;
`

const MenuLI = styled.li`
  margin-left: 25px;

  ${props => props.locale === 'fr' ? tw`text-center` : ''}
`

const DividerFloat = styled.div`
  width: 1px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  ${tw`bg-white`}
`

const Divider = styled.div`
  width: 1px;
  height: 35px;
  margin-right: 25px;
  margin-left: 25px;
  ${tw`bg-black`}
`

const PromoWrapper = styled.div`
  width: 100px;
  height: 100%;
  margin-right: 25px;
  margin-left: 25px;

  img {
    max-width: 100%;
  }
`

const SocialWrapper = styled.div`
  ${tw`flex flex-col justify-between`}
  height: 60px;
  margin-right: 65px;
`

const SocialIcon = styled.a`
  font-size: 14px;
  line-height: 1;

  img {
    border: 0px;
    width: auto;
    margin: 0 auto;
  }
`

const MobileMenuWrapper = styled.div`
  ${tw`flex px-5 py-3 justify-between`}

  ${props => props.type === 'float' ? tw`absolute pin-x z-10` : null}
`

const types = {
  float: FloatMenu,
  default: DefaultMenu
}

const Header = ({ children, type, locale, urls, intl }) => {
  const MenuType = types[type];
  const menuItems = [
    {
      to: "08421dc2-480c-4c99-b6d5-834990b6fe7f",
      name: intl.formatMessage({ id:"menu.product" }),
      internal: true,
      target:'_self'
    },
    {
      to: "85fb8531-f0f6-4216-98ee-53c2e0e87b69",
      name: intl.formatMessage({ id:"menu.home_solutions" }),
      internal: true,
      target:'_self'
    },
    {
      to: locale == 'fr' ? "/fr/ami":"/pets",
      name: intl.formatMessage({ id:"menu.pets" }),
      internal: false,
      target:'_self'
    },
    {
      to: locale == 'fr' ? "/fr/fierrrementcanadienne":"/purr-oudlycanadian",
      name: intl.formatMessage({ id:"menu.about" }),
      internal: false,
      target:'_self'
    },
    {
      to: "http://explore.icpcareers.com/",
      name: intl.formatMessage({ id:"menu.careers" }),
      internal: false,
      target:'_blank'
    }
  ]

  if(locale != 'en' && locale != 'fr'){
    menuItems.splice(2,1)
  }

  // Remove home solutions for Chinese
  if (intl.locale === 'zh-hant' || intl.locale === 'zh-hans') {
    delete menuItems[1]
  }

  let logoLink = '/'

  if (intl.locale === 'fr') {
    logoLink = '/fr'
  } else if (intl.locale === 'zh-hant') {
    logoLink = '/zh-hant'
  } else if (intl.locale === 'zh-hans') {
    logoLink = '/zh-hans'
  }

  return (
    <Container>
      <IsDesktop>
        <MenuType>
          <Menu>
            <LogoWrapper>
              <Link to={logoLink}>
                <img src={logo} alt="Royale" />
              </Link>
            </LogoWrapper>
            <RightMenu type={type}>
              <MenuUL>
                {menuItems.map(item => (
                  <MenuLI locale={intl.locale} key={item.name}>
                    {item.internal
                      ?
                        <MenuItem to={item.to} type={type} target={item.target} internal>{item.name}</MenuItem>
                      :
                        <MenuItem to={item.to} type={type} target={item.target}>{item.name}</MenuItem>
                    }
                  </MenuLI>
                ))}
              </MenuUL>
              {type === 'float' ? <DividerFloat /> : <Divider /> }
              <LanguageSwitcher urls={urls} locale={locale} type={type} />
              {(intl.locale === 'zh-hant' || intl.locale === 'zh-hans') ? null : (
                <PromoWrapper>
                  <a href={(locale === 'en') ? 'http://www.smartsource.ca/smartsource2/static_content/app/#/index.html?Link=V7JBJRKNLNCOI' : 'http://www.smartsource.ca/smartsource2/static_content/app/#/index.html?Link=V7JBJRKNLNCOI&locale=fr_CA'} target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ appnexus: 'https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2', ga: { category: 'Coupon_Click', action: 'Click', Label: (locale === 'en') ? 'Menu_Coupon_Masterband' : 'Menu_Coupon_Masterband_FR'}})}>
                    <img src={(locale === 'en') ? Badge : BadgeFR} alt="Coupon" />
                  </a>
                </PromoWrapper>
              )}
              <SocialWrapper>
                <SocialIcon href="https://www.facebook.com/RoyaleKittensChatons" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', label: (locale === 'en') ? 'Facebook_Click' : 'Facebook_Click_FR'}})}>
                  {(type === 'float') ? <img src={socialfb} alt="Facebook" /> : <img src={socialgrayfb} alt="Facebook" /> }
                </SocialIcon>
                <SocialIcon href="https://www.instagram.com/theroyalekittens/" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', label: (locale === 'en') ? 'Instagram_Click' : 'Instagram_Click_FR'}})}>
                  {(type === 'float') ? <img src={socialinsta} alt="Instagram" /> : <img src={socialgrayinsta} alt="Instagram" />}
                </SocialIcon>
              </SocialWrapper>
            </RightMenu>
          </Menu>
        </MenuType>
      </IsDesktop>

      <IsMobile>
        <MobileMenuWrapper type={type}>
          <LogoWrapper>
            <Link to={intl.locale === 'en' ? '/' : '/fr'}>
              <img src={logo} alt="Royale" />
            </Link>
          </LogoWrapper>

          <MobileMenu
            menuItems={menuItems}
            locale={locale}
            urls={urls}
            type={type}
          />
        </MobileMenuWrapper>
      </IsMobile>

      {children}
    </Container>
  )
}

Header.propTypes = {
  // Either float or default
  type: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.node,
  // Used to change languages
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }).isRequired,
  // used to determine current langage
  locale: PropTypes.string
}

Header.defaultProps = {
  type: 'default',
  children: null,
  locale: 'en'
}

export default injectIntl(Header)
