import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const LanguageSwitcherStyle = styled.div`
  ${tw`border-b border-solid border-transparent font-montserrat font-semibold text-black text-sm leading-tight no-underline ml-3`}

  > div > span {
    ${tw`flex items-center cursor-pointer outline-none focus:shadow-outline`}
  }
`

const LanguageSwitcherMenu = styled.ul`
  ${tw`list-reset m-0`}

  button {
    ${tw`m-0 p-0 border-0 appearance-none`}
    padding: 10px 0px;
    background: none;
  }
`

class LanguageSwitcherMobile extends Component {
  constructor(props) {
    super(props);

    this.locales = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-hant',
        label: 'Chinese Traditional'
      },
      {
        value: 'zh-hans',
        label: 'Chinese Simplified'
      }
    ]

    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(selection) {
    const { urls } = this.props

    if (typeof window !== 'undefined') {
      if (selection === 'en') {
        window.location = urls.en
      }

      if (selection === 'fr') {
        window.location = urls.fr
      }

      if (selection === 'zh-hans') {
        window.location = urls.zh_hans
      }

      if (selection === 'zh-hant') {
        window.location = urls.zh_hant
      }
    }
  }

  render() {
    return (
      <LanguageSwitcherStyle>
        <LanguageSwitcherMenu>
          {
            this.locales.map((item) => (
              <li key={item.value}>
                <button type="button" onClick={() => this.changeLanguage(item.value)}>
                  {item.label}
                </button>
              </li>
            ))
          }
        </LanguageSwitcherMenu>
      </LanguageSwitcherStyle>
    )
  }
}

LanguageSwitcherMobile.propTypes = {
  // URL to change too
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }).isRequired
}

export default LanguageSwitcherMobile
