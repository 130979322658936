import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {injectIntl} from 'react-intl';

import fixInternalLinks from '../../../helpers/fixInternalLinks'

const ProductTitle = styled.h3`
  ${tw`font-montserrat font-medium text-xl leading-tight text-black m-0 mt-5 text-center`}

  p {
    margin: 0px;
  }
`

const ProductLink = styled(Link)`
  ${tw`no-underline text-center`}

  :hover ${ProductTitle},
  :focus ${ProductTitle} {
    text-decoration: underline;
  }
`

const ProductImageWrapper = styled.div`
  height: 270px;
  position: relative;
  ${tw`flex items-center`}

  img {
    margin: 0 auto;
    height: auto;
  }
`

const ProductBlock = (props) => {
  const { title, alt, guid, image, intl } = props;
  return (
    <ProductLink to={fixInternalLinks(guid, intl.locale)}>
      <ProductImageWrapper>
        <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={image} data-imageboss-height={270} data-imageboss-operation="height" alt={alt} loading="lazy" />
      </ProductImageWrapper>

      <ProductTitle
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </ProductLink>
  )
}

ProductBlock.propTypes = {
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  // Fixed image
  image: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired
}

ProductBlock.defaultProps = {}

export default injectIntl(ProductBlock)
