import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import {injectIntl} from 'react-intl';

import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'
import { Flex, Box } from '../../common/Grid'
import Slider from '../../common/Slider'
import Container from '../../common/Container'
import ProductBlock from '../../common/ProductBlock'
import VideoBlock from '../VideoBlock'

const ProductCategoryBlockWrapper = styled.section`
  ${tw`mb-32`}

  @media screen and (max-width: 64em) {
    ${tw`mb-20`}
  }
`

const Description = styled.div`
  ${tw`mt-8 mb-8 font-raleway font-medium text-lg leading-normal text-blog-text`}

  p {
    margin: 0px;
  }

  h2 {
    ${tw`font-light`}
  }

  a.coupon {
    ${tw`outline-none font-montserrat text-sm text-center no-underline cursor-pointer text-white mt-8 -mb-10`}
    padding: 15px 30px;
    border: 1px solid #4d8dcd;
    background-color: #4d8dcd;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    line-height: 1.258;
    width: auto;
    color: '#fff';
    border-radius: 9999px;
    display: inline-block;

    &:hover,
    &:focus {
      background-color: #6db3f8;
      border-color: #6db3f8;
      color: #fff;
      ${tw`shadow-outline`}
    }
  }
`

const ProductHeader = styled.h2`
  ${tw`font-montserrat font-light text-4xl leading-tight text-black m-0 my-20`}
  max-width: 500px;

  p {
    margin: 0px;
  }

  @media screen and (max-width: 64em) {
    ${tw`mb-6 text-3xl`}
  }
`

const BlueContainer = styled.div`
  ${tw`bg-light-blue`}

  @media screen and (min-width: 64em) {
    padding: 100px 0px;
    margin: ${props => (props.video === 'yes') ? '-70px 0px -150px 0px' : '-70px 0px -50px 0px' };
  }

  @media screen and (max-width: 64em) {
    padding: 70px 0px 50px;
    margin: ${ props => (props.video === 'yes') ? '-90px 0px -150px 0px' : '-90px 0px -50px 0px' };
  }
`

const BlueBottomContainer = styled.div`
  ${tw`bg-white pt-10`}

  ${ props => (props.video === 'yes') ? tw`pt-10` : tw`p-0` };
`

const ProductCategoryBlock = (props) => {
  const {
    modification,
    title,
    video,
    product_header,
    logo_image,
    products,
    guid,
    intl
  } = props;

  let {
    description,
    product_category_image
  } = props;

  // HACK UNTIL COUPONS ARE DONE BY ITD
  if (process.env.GATSBY_ACTIVE_ENV === 'production') {

    let miq = '';
    let ga = '';

    switch (guid) {
      // BATHROOM TISSUE
      case '7828b95f-2724-4e2c-b389-631e42955091':
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        if (intl.locale === 'en') {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Velour_Coupon_Click'}):null;";
        } else {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Velour_Coupon_Click_FR'}):null;";
        }
        break;
      // TIGER TOWEL
      case 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae':
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        miq += "var a2img=document.createElement('img');a2img.style.width='1px';a2img.style.height='1px';a2img.src='https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:yotvgnb&fmt=3';document.body.appendChild(a2img);";
        if (intl.locale === 'en') {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Tiger_Towel_Coupon_Click'}):null;";
        } else {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Tiger_Towel_Coupon_Click_FR'}):null;";
        }
        break;
      // FACIAL TISSUE
      case 'bbca9194-963a-4ad3-b701-80387cb652ba':
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        if (intl.locale === 'en') {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Facial_Coupon_Click'}):null;";
        } else {
          ga = "(window.ga)?window.ga('send','event',{eventCategory:'Coupon_Click',eventAction:'Click',eventLabel:'Facial_Coupon_Click_FR'}):null;";
        }
        break;
      default:
    }

    description = description.replace('class="coupon"', `class="coupon" onClick="fbq('track','Purchase');gtag('event','conversion',{'send_to':'AW-951001886/S9_KCIL27pIBEJ7GvMUD'});${miq}${ga}"`)
  }

  // TEMP HACK UNTIL PRODUCTS DONE
  // SV
  if (guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae') {
    product_category_image = 'https://royale.imgix.net/contentassets/2aca4a3d4b3f4ed48c2da5178b2cbdf6/tt_heroimage.jpg';
  }
  // END OF HACK
  // SV

  return (
    <ProductCategoryBlockWrapper id={guid}>
      <Container modification={modification}>
        {logo_image ? <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={logo_image} data-imageboss-height="200" data-imageboss-operation="height" alt={striptags(title)} loading="lazy" /> : null}
      </Container>

      <IsDesktop>
        <BlueContainer video={(video) ? 'yes' : 'no'}>
          <Container width={1060}>
            <Flex>
              <Box width={1/2} pr={6}>
                <Description dangerouslySetInnerHTML={{ __html: description }} />

                {video ? <VideoBlock url={video} height={300} /> : null }
              </Box>

              <Box width={1 / 2}>
                {product_category_image ? <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={product_category_image} data-imageboss-width="530" alt="" loading="lazy" /> : null}
              </Box>
            </Flex>
          </Container>
        </BlueContainer>

        <BlueBottomContainer video={(video) ? 'yes' : 'no'}>
          <Container width={1060}>
            <ProductHeader dangerouslySetInnerHTML={{ __html: product_header }} />

            <Flex>
              {(products && products.length <= 3) ? products.map(product => (
                product && product.images_url.length > 0 ?
                  (
                    <Box width={1 / 3} px={4}>
                      <ProductBlock
                        guid={product.guid}
                        title={product.title_html}
                        alt={product.title}
                        image={product.images_url[0]}
                      />
                    </Box>
                  ) : null
              )) : null}
            </Flex>
          </Container>
        </BlueBottomContainer>

        {(products && products.length > 3) ?
          (
            <Slider>
              {products.map(product => (
                product && product.images_url.length > 0 ?
                  (
                    <ProductBlock
                      guid={product.guid}
                      title={product.title_html}
                      alt={product.title}
                      image={product.images_url[0]}
                    />
                  ) : null
              ))}
            </Slider>
          )
          : null}
      </IsDesktop>

      <IsMobile>
        <BlueContainer video={(video) ? 'yes' : 'no'}>
          <Container width={1060}>
            <Flex>
              <Box>
                <Description dangerouslySetInnerHTML={{ __html: description }} />

                {video ? <VideoBlock url={video} height={300} /> : null}
              </Box>
            </Flex>
          </Container>
        </BlueContainer>

        <BlueBottomContainer video={(video) ? 'yes' : 'no'}>
          <Container width={1060}>
            <ProductHeader dangerouslySetInnerHTML={{ __html: product_header }} />
          </Container>
        </BlueBottomContainer>

        {(products && products.length > 1) ?
          (
            <Slider>
              {products.map(product => (
                product && product.images_url.length > 0 ?
                  (
                    <ProductBlock
                      guid={product.guid}
                      title={product.title_html}
                      alt={product.title}
                      image={product.images_url[0]}
                    />
                  ) : null
              ))}
            </Slider>
          )
          :
          (
            <ProductBlock
              guid={products[0].guid}
              title={products[0].title_html}
              alt={products[0].title}
              image={products[0].images_url[0]}
            />
          )
          }
      </IsMobile>

    </ProductCategoryBlockWrapper>
  )
}

ProductCategoryBlock.propTypes = {
  guid: PropTypes.string.isRequired,
  modification: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  video: PropTypes.string,
  product_header: PropTypes.string.isRequired,
  logo_image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  product_category_image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  products: PropTypes.arrayOf([
    PropTypes.objectOf({
      title: PropTypes.string,
      images: PropTypes.string,
      guid: PropTypes.string
    })
  ]).isRequired
}

ProductCategoryBlock.defaultProps = {
  modification: '',
  video: null
}

export const productCategoryBlockFragment = graphql`
  fragment productCategoryBlockFragment on ProductCategoryBlock {
    guid
    id
    modification
    description
    video
    product_header
    logo_image_url
    product_category_image_url
    products {
      id
      title
      title_html
      guid
      images_url
    }
  }
`

export default injectIntl(ProductCategoryBlock)
