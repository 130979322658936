import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {RichText} from "prismic-reactjs";

const NumberedListWrapper = styled.div`
  ol {
    ${tw`list-reset m-0 mb-10`}
    counter-reset: item;

    li {
      ${tw`block relative font-montserrat font-light text-blog-text text-2xl leading-normal pl-20 pt-6 pb-6`}
      counter-increment: item;

      a {
        ${tw`text-border-blue no-underline hover:underline focus:underline`}
      }

      p {
        margin: 0px 0px 10px 0px;
      }

      &:before {
        content: counter(item);
        position: absolute;
        left: 0px;
        ${tw`font-montserrat text-2xl text-border-blue font-light text-center`}
        width: 5rem;
      }

      &:nth-child(even) {
        ${tw`bg-light-blue`}
      }
    }
  }

  @media screen and (max-width: 64em) {
    ${tw`-ml-5 -mr-5`}
    ol {
      li {
        ${tw`p-0 mb-5 pt-16`}
        & > div {
          ${tw`pt-12 pl-5 pr-5`}
        }

        p {
          ${tw`px-5`}
        }

        p.block-img {
          ${tw`p-0`}
        }

        &:before {
          content: counter(item);
          position: absolute;
          left: 1.25rem;
          top: 15px;
          ${tw`font-montserrat text-2xl text-border-blue font-light text-center`}
          width: auto;
        }
      }
    }
  }
`

const NumberedListHeader = styled.h3`
  ${tw`font-montserrat font-normal text-2xl text-black block w-full border-0 border-border-blue border-b border-solid pb-6 m-0 mb-6`}
  line-height: 29px;

  @media screen and (max-width: 64em) {
    ${tw`px-5`}
  }
`

const NumberedListBlock = (props) => {
  const { title, list, modification } = props;
  return (
    <NumberedListWrapper modification={modification}>
      <NumberedListHeader>{title}</NumberedListHeader>

      <ol>
        {list && list.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <RichText render={item.item} />
          </li>
        ))}
      </ol>
    </NumberedListWrapper>
  )
}

NumberedListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.string.isRequired,
  modification: PropTypes.string
}

NumberedListBlock.defaultProps = {
  modification: ''
}

export default NumberedListBlock
