import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Slider from "react-slick";
import '../../../assets/css/slick.css'

import Container from '../../common/Container'
import Icon from '../../common/Icon'

const BrowseStyles = css`
  ${tw`my-20`}
`

const GalleryBlockWrapper = styled.div`
  ${tw`relative`}
  ${props => (props.modification.includes('styles')) ? BrowseStyles : null}
`

const GalleryBlockHeader = styled.h2`
  ${tw`font-montserrat font-light text-black text-5xl m-0 mb-6`}

  p {
    margin: 0px;
  }
`

const ArrowButton = css`
  ${tw`bg-none border-none absolute outline-none focus:shadow-outline p-0 z-10 cursor-pointer`}

  svg {
    ${tw`z-10 relative`}
  }
`

const NextArrowButton = styled.button`
  ${ArrowButton}
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 70px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);

  &:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
`

const PrevArrowButton = styled.button`
  ${ArrowButton}
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 70px;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 100px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
`

const SlideItem = styled.div`
  ${tw`px-5`}
`

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <NextArrowButton
      type="button"
      className={className}
      onClick={onClick}
    >
      <Icon icon="chevron" strokeWidth={2} size={20} stroke='#4D8DCD' />
    </NextArrowButton>
  );
}

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <PrevArrowButton
      type="button"
      className={className}
      onClick={onClick}
    >
      <Icon icon="chevron" strokeWidth={2} size={20} left stroke='#4D8DCD' />
    </PrevArrowButton>
  );
}

const GalleryBlock = (props) => {
  const { modification, images, title } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: (images.length < 4) ? images.length : 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  return (
    <GalleryBlockWrapper modification={`${modification} ${title ? 'styles' : null}`}>
      <Container width={1040}>
        {title ? <GalleryBlockHeader dangerouslySetInnerHTML={{__html:title}} /> : null }
      </Container>
      <Slider {...settings}>
        {images.map((image, key) => (
          // eslint-disable-next-line
          <SlideItem index={key}>
            <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={image} alt='' loading="lazy" />
          </SlideItem>
        ))}
      </Slider>
    </GalleryBlockWrapper>
  )
}

GalleryBlock.propTypes = {
  modification: PropTypes.string,
  images: PropTypes.arrayOf([
    PropTypes.string
  ]).isRequired
}

GalleryBlock.defaultProps = {
  modification: ''
}

export const galleryBlockFragment = graphql`
  fragment galleryBlockFragment on GalleryBlock {
    id
    title_html
    title
    images_url
  }
`

export default GalleryBlock
