import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {injectIntl} from 'react-intl';
import { css } from '@emotion/core'
import striptags from 'striptags'
import { RichText } from 'prismic-reactjs';

import sendEvent from '../../../helpers/sendAnalytics'

import { Flex, Box } from '../../common/Grid'
import Button from '../../common/Button'
import Icon from '../../common/Icon'

const TipHeader = styled.h3`
  ${tw`font-montserrat text-5xl font-light leading-none text-black m-0 mt-5`}

  max-width: 200px;

  p {
    margin: 0px;
  }

  .sup,
  sup {
    font-size: 50%;
    top: -.7em;
  }
`

const TipBody = styled.div`
  ${tw`font-raleway text-base font-normal leading-normal text-black m-0 mb-5 mt-16`}

  p {
    margin: 0px;
  }

  & {
    margin-top: 20px;
  }

  @media screen and (max-width: 40em) {
    ${tw`mt-6`}
  }
`

const GufsStyles = css`
  ${TipHeader} {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  ${TipBody} {
    margin-top: 0px;
  }

  img {
    max-width: 350px;
  }

  a {
    border: 0px;
    background-image: linear-gradient(134deg, #C7DC56 0%, #006838 100%);

    p {
      color: #fff;
    }

    &:focus,
    &:hover {
      border: 0px;
      box-shadow: 0 0 0 3px rgba(0,104,56,0.5);
    }
  }

  @media screen and (max-width: 40em) {
    img {
      max-width: 100%;
    }
  }
`

const TipContainer = styled.div`
  ${tw`mt-12 mb-12 pt-16 pb-16`}
  ${props => (props.modification && props.modification.includes('gufs')) ? GufsStyles : null}
  background: ${props => ((props.color) ? props.color : 'rgba(77,141,205,0.05)')};
`

const Container = styled.div`
  width: 100%;
  max-width: 830px;
  margin: 0 auto;

  @media screen and (max-width: 64em) {
    ${tw`px-5`}
  }
`

const HouseIcon = styled(Icon)`
  max-width: 250px;

  @media screen and (max-width: 64em) {
    max-width: 150px;
  }
`

const TipBlock = (props) => {
  const { title, icon, body, image, color, style, buttonUrl, buttonName, intl } = props;
  return (
    <TipContainer modification={style} color={color}>
      {style === 'gufs' ?
        (
          <Container>
            <Flex flexWrap="wrap" alignItems='center' justifyContent='space-between'>
              <Box width={[1,1,1,1/2]} mb={[3,3,3,0]}>
                {icon ? <Icon icon={icon ? icon.toLowerCase() : ''} width="40" /> : null }
                {image ? <img src={image} alt="" /> : null }
                {image ? null : <TipHeader dangerouslySetInnerHTML={{__html:title[0].text}} /> }
              </Box>

              <Box width={[1, 1, 1, 1 / 2]}>
                {image ? <TipHeader dangerouslySetInnerHTML={{__html:striptags(title[0].text, ['span','sup'])}} /> : null }
                <TipBody>
                  <RichText render={body} />
                </TipBody>
                {buttonUrl && buttonUrl.slug ? (
                  <Button
                    convert={false}
                    to={`${(intl.locale === 'en') ? '/home-solutions/' : '/fr/solutions-maison/'}${buttonUrl.slug}`}
                    onClick={() => sendEvent({ga:{category:'Home_Solutions',action:'Article_Tip',label:(intl.locale === 'en') ? 'Garden_Tip' : 'Garden_Tip_FR'}})}
                  >
                    {buttonName}
                  </Button>
                ) : null }
                {buttonUrl && buttonUrl.url ? (
                  <Button
                    convert={false}
                    to={buttonUrl.url}
                    onClick={() => sendEvent({ga:{category:'Home_Solutions',action:'Article_Tip',label:'gufs_tip'}})}
                  >
                    {buttonName}
                  </Button>
                ) : null }
              </Box>
            </Flex>
          </Container>
        ) : (
          <Container>
            <Flex flexWrap="wrap" alignItems='center' justifyContent='space-between'>
              <Box width={[1,1,1,1/2]} mb={[3,3,3,0]}>
                <HouseIcon icon="room" />
              </Box>

              <Box width={[1, 1, 1, 1 / 2]}>
                <TipHeader dangerouslySetInnerHTML={{__html:striptags(title[0].text, ['span','sup'])}} className="slim" />
                <TipBody className="slim">
                  <RichText render={body} />
                </TipBody>
                {buttonUrl && buttonUrl._linkType === 'Link.document' ? (
                  <Button
                    convert={false}
                    to={`${(intl.locale === 'en') ? '/home-solutions/' : '/fr/solutions-maison/'}${buttonUrl._meta.uid}`}
                    onClick={() => sendEvent({ga:{category:'Home_Solutions',action:'Article_Tip',label:(intl.locale === 'en') ? 'Garden_Tip' : 'Garden_Tip_FR'}})}
                  >
                    {buttonName}
                  </Button>
                ) : null }
                {buttonUrl && buttonUrl.url ? (
                  <Button
                    convert={false}
                    to={buttonUrl.url}
                    onClick={() => sendEvent({ga:{category:'Home_Solutions',action:'Article_Tip',label:'gufs_tip'}})}
                  >
                    {buttonName}
                  </Button>
                ) : null }
              </Box>
            </Flex>
          </Container>
        )
      }
    </TipContainer>
  )
}

TipBlock.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired
}

TipBlock.defaultProps = {}

export default injectIntl(TipBlock)
