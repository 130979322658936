const consoleLogDetails = (name, details) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line no-console
    console.log(`%cSENT EVENT - ${name}:`, 'background: green; color: white; display: block; text-align: center; font-weight: bold; width: 100%;');
    // eslint-disable-next-line no-console
    if (typeof details === 'object' && details !== null) {
      console.table(details);
    } else {
      console.log(details);
    }
  }
}

/**
 * Expects an object of different events to fire
 *
 * GTAG
 *
 * gtag object with any parameters passed to third option (such as {'send_to': 'AW-951001886/QUK8CKqC-5IBEJ7GvMUD'})
 *
 * GA
 *
 * ga object with following values
 *
 * {
 *   category: GA category,
 *   action: GA action,
 *   label: GA label,
 *   hitCallback: function that redirects user to url after sending event (only used on _self targets)
 * }
 *
 * APPNEXUS
 *
 * a appnexus URL, this adds an image to the body (used for pixel tracking) this should be the full URL of the image
 *
 * SNAPCHAT
 *
 * a tag name to send as a track event
 *
 * @param {Object} details
 */
const sendEvent = (details) => {
  if (typeof window !== "undefined") {
    if (typeof details === 'object' && details !== null) {
      // Send google tag manager event
      if (details.gtag && Object.keys(details.gtag).length !== 0) {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          window.gtag("event", "conversion", { ...details.gtag })
        } else {
          consoleLogDetails('gtag', details.gtag);
        }
      }

      // Send google analytics event
      if (details.ga && Object.keys(details.ga).length !== 0 && details.ga.category !== '') {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          window.ga(`send`, `event`, {
            eventCategory: details.ga.category,
            eventAction: details.ga.action,
            eventLabel: details.ga.label,
            transport: (typeof details.ga.hitCallback === "function") ? `beacon` : ``,
            hitCallback: (typeof details.ga.hitCallback === "function") ? details.ga.hitCallback : null,
          })
        } else {
          consoleLogDetails('ga', details.ga);
        }
      }

      // Send appnexus event (adnxs)
      if (details.appnexus) {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          const appNexusImage = document.createElement("img");
          appNexusImage.src = details.appnexus;
          appNexusImage.style.width = '1px';
          appNexusImage.style.height = '1px';

          document.body.appendChild(appNexusImage);
        } else {
          consoleLogDetails('appnexus', details.appnexus);
        }
      }

      // Send image event
      if (details.img && details.img.length) {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          details.img.forEach((item) => {
            const imgTag = document.createElement("img");
            imgTag.src = item;
            imgTag.style.width = '1px';
            imgTag.style.height = '1px';

            document.body.appendChild(imgTag);
          });
        } else {
          consoleLogDetails('img', details.img);
        }
      }

      if (details.fb) {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          if (window.fbq && details.fb !== '') {
            window.fbq('track', details.fb);
          }
        } else {
          consoleLogDetails('fb', details.fb);
        }
      }

      if (details.snapchat) {
        if (process.env.GATSBY_ACTIVE_ENV === 'production' && process.env.GATSBY_EVENT_TRACKING !== 'debug') {
          if (window.snaptr && details.snapchat !== '') {
            window.snaptr('track', details.snapchat);
          }
        } else {
          consoleLogDetails('snapchat', details.snapchat);
        }
      }
    }
  }
}

export default sendEvent
