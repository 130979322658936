import React from 'react'
import PropTypes from 'prop-types'
import striptags from 'striptags'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const AboutUsImageStyles = css`
  ${tw`mb-30`}
`

const ImageBlockWrapper = styled.div`
  ${props => (props.modification.includes('aboutus')) ? AboutUsImageStyles : null}
  ${tw`mb-12`}

  @media screen and (max-width: 64em) {
    ${tw`-ml-5 -mr-5`}
  }
`

const ImageBlock = (props) => {
  const { modification, image, alt, inside } = props;

  return (
    <ImageBlockWrapper modification={modification} inside={inside}>
      <img alt={striptags(alt)} src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={image} loading="lazy" />
    </ImageBlockWrapper>
  )
}

ImageBlock.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.string,
  inside: PropTypes.bool
}

ImageBlock.defaultProps = {
  modification: '',
  image: '',
  inside: false
}

export const ImageBlockFragment = graphql`
  fragment imageBlockFragment on ImageBlock {
    id
    modification
    image_url
  }
`

export default ImageBlock
