import React from 'react'
import styled from '@emotion/styled'
import Carousel from 'nuka-carousel'

import IsDesktop from '../IsDesktop'
import IsMobile from '../IsMobile'

const PrevButtonStyles = styled.button`
  ${tw`bg-transparent border-none outline-none hover:shadow-outline focus:shadow-outline p-0 ml-10`}

  svg {
    display: block;
  }
`

const NextButtonStyles = styled.button`
  ${tw`bg-transparent border-none outline-none hover:shadow-outline focus:shadow-outline p-0 mr-10`}
  svg {
    display: block;
  }
`

const PrevButton = (props) => {
  const { currentSlide, wrapAround, slideCount, previousSlide} = props
  const disabled =
    (currentSlide === 0 && !wrapAround) ||
    slideCount === 0;
  return (
    <PrevButtonStyles
      type='button'
      disabled={disabled}
      onClick={previousSlide}
      aria-label="previous"
    >
      <svg width="16px" height="28px" viewBox="0 0 16 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
          <g id="06-Bath-Product-Page" transform="translate(-1370.000000, -1765.000000)" stroke="#4D8DCD" strokeWidth="2">
            <polyline transform="translate(1378.000000, 1779.000000) rotate(180.000000) translate(-1378.000000, -1779.000000) " points="1372 1767 1384 1779 1372 1791" />
          </g>
        </g>
      </svg>
    </PrevButtonStyles>
  )
}

const NextButton = (props) => {
  const { currentSlide, wrapAround, slideCount, nextSlide } = props
  const disabled =
    (currentSlide === 0 && !wrapAround) ||
    slideCount === 0;
  return (
    <NextButtonStyles
      type='button'
      disabled={disabled}
      onClick={nextSlide}
      aria-label="next"
    >
      <svg width="16px" height="28px" viewBox="0 0 16 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
          <g id="06-Bath-Product-Page" transform="translate(-1370.000000, -1765.000000)" stroke="#4D8DCD" strokeWidth="2">
            <polyline id="Shape" points="1372 1767 1384 1779 1372 1791" />
          </g>
        </g>
      </svg>
    </NextButtonStyles>
  )
}

const ReactCarousel = styled(Carousel)`
  overflow: hidden;

  .slider-frame {
    max-width: 1060px;
    margin: 0 auto !important;
  }

  .slider-frame > .slider-list {
    /* temp until https://github.com/FormidableLabs/nuka-carousel/issues/417 is fixed */
    height: 370px !important;

    width: auto !important;
  }
`

const Slider = ({ children }) => (
  <React.Fragment>
    <IsDesktop>
      <ReactCarousel
        slidesToShow={3}
        slidesToScroll={1}
        renderBottomCenterControls={null}
        renderCenterLeftControls={props => <PrevButton {...props} />}
        renderCenterRightControls={props => <NextButton {...props} />}
        cellSpacing={100}
        cellAlign='left'
        autoGenerateStyleTag={false}
        wrapAround
        easing='easeCubicInOut'
        speed={300}
      >
        {children}
      </ReactCarousel>
    </IsDesktop>
    <IsMobile>
      <ReactCarousel
        slidesToShow={1}
        slidesToScroll={1}
        renderBottomCenterControls={null}
        renderCenterLeftControls={props => <PrevButton {...props} />}
        renderCenterRightControls={props => <NextButton {...props} />}
        cellSpacing={100}
        cellAlign='left'
        autoGenerateStyleTag={false}
        wrapAround
        easing='easeCubicInOut'
        speed={300}
      >
        {children}
      </ReactCarousel>
    </IsMobile>
  </React.Fragment>
)

export default Slider
