import React from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import { Flex, Box } from '../../common/Grid'
import Container from '../../common/Container'
import {
  TextBlock
} from '..'

const ThreeColumnWithIconsWrapper = styled.div`
  ${tw`bg-light-blue my-10 py-20`}

  @media screen and (max-width: 64em) {
    ${tw`py-10`}
  }
`

const ThreeColumnWithIconsTextWrapper = styled.div`
  ${tw`mt-5`}
`

const IconImg = styled.img`
  max-width: 50px;
`

const ThreeColumnWithIconsBlock = (props) => {
  const {
    content_1,
    content_2,
    content_3,
    icon_1,
    icon_2,
    icon_3
  } = props

  return (
    <ThreeColumnWithIconsWrapper>
      <Container>
        <Flex mx={[0, 0, 0, -3]} flexWrap="wrap">
          <Box width={[1, 1, 1, 1/3]} px={[0, 0, 0, 3]}>
            <IconImg src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={icon_1} data-imageboss-operation="width" data-imageboss-options="withoutEnlargement:1" data-imageboss-width="50" alt='' loading="lazy" />
            <ThreeColumnWithIconsTextWrapper>
              {content_1.map((block) => {
                let { text } = block

                text = text
                  .replace('title="YouTube"', "title='YouTube' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:(document.documentElement.lang==='en')?'About_Us_YouTube':'About_Us_YouTube_FR'}):null\"")
                  .replace('title="Careers"', "title='Careers' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link'}):null\"")
                  .replace('title="Carrières"', "title='Carrières' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link_FR'}):null\"")
                  .replace('title="Learn More"', "title='Learn More' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link'}):null\"")
                  .replace(`title="Plus d'info"`, "title='Plus d'info' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link_FR'}):null\"")

                // eslint-disable-next-line dot-notation
                switch (block['__typename']) {
                  case 'TextBlock':
                    return <TextBlock modification={block.modification} text={text} key={block.guid} />
                  default:
                    // eslint-disable-next-line dot-notation
                    console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
                }

                return null
              })}
            </ThreeColumnWithIconsTextWrapper>
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
            <IconImg src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={icon_2} data-imageboss-operation="width" data-imageboss-options="withoutEnlargement:1" data-imageboss-width="50" alt='' loading="lazy" />
            <ThreeColumnWithIconsTextWrapper>
              {content_2.map((block) => {
                let { text } = block

                text = text
                  .replace('title="YouTube"', "title='YouTube' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:(document.documentElement.lang==='en')?'About_Us_YouTube':'About_Us_YouTube_FR'}):null\"")
                  .replace('title="Careers"', "title='Careers' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link'}):null\"")
                  .replace('title="Carrières"', "title='Carrières' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link_FR'}):null\"")
                  .replace('title="Learn More"', "title='Learn More' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link'}):null\"")
                  .replace(`title="Plus d'info"`, "title='Plus d'info' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link_FR'}):null\"")

                // eslint-disable-next-line dot-notation
                switch (block['__typename']) {
                  case 'TextBlock':
                    return <TextBlock modification={block.modification} text={text} key={block.guid} />
                  default:
                    // eslint-disable-next-line dot-notation
                    console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
                }

                return null
              })}
            </ThreeColumnWithIconsTextWrapper>
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
            <IconImg src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={icon_3} data-imageboss-operation="width" data-imageboss-options="withoutEnlargement:1" data-imageboss-width="50" alt='' loading="lazy" />
            <ThreeColumnWithIconsTextWrapper>
              {content_3.map((block) => {
                let { text } = block

                text = text
                  .replace('title="YouTube"', "title='YouTube' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:(document.documentElement.lang==='en')?'About_Us_YouTube':'About_Us_YouTube_FR'}):null\"")
                  .replace('title="Careers"', "title='Careers' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link'}):null\"")
                  .replace('title="Carrières"', "title='Carrières' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'Careers_External_Link_FR'}):null\"")
                  .replace('title="Learn More"', "title='Learn More' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link'}):null\"")
                  .replace(`title="Plus d'info"`, "title='Plus d'info' onclick=\"(window.ga)?window.ga('send','event',{eventCategory:'Engagement',eventAction:'Click',eventLabel:'ICP_Learn_More_External_Link_FR'}):null\"")

                // eslint-disable-next-line dot-notation
                switch (block['__typename']) {
                  case 'TextBlock':
                    return <TextBlock modification={block.modification} text={text} key={block.guid} />
                  default:
                    // eslint-disable-next-line dot-notation
                    console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
                }

                return null
              })}
            </ThreeColumnWithIconsTextWrapper>
          </Box>
        </Flex>
      </Container>
    </ThreeColumnWithIconsWrapper>
  )
}

ThreeColumnWithIconsBlock.propTypes = {}

ThreeColumnWithIconsBlock.defaultProps = {}

export const ThreeColumnWithIconsBlockFragment = graphql`
  fragment threeColumnWithIconsBlockFragment on ThreeColumnWithIconsBlock {
    modification
    id
    icon_1_url
    icon_2_url
    icon_3_url

    content_1 {
      __typename
      ... on TextBlock {
        guid
        modification
        text
      }
    }

    content_2 {
      __typename
      ... on TextBlock {
        guid
        modification
        text
      }
    }

    content_3 {
      __typename
      ... on TextBlock {
        guid
        modification
        text
      }
    }
  }
`

export default ThreeColumnWithIconsBlock
