/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { Link } from "gatsby"
import {injectIntl} from 'react-intl';

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import sendEvent from '../../../helpers/sendAnalytics'

const MenuItemFloatStyle = css`
  ${tw`
    border-0 border-b border-solid border-transparent
    font-montserrat
    text-white
    text-sm
    leading-tight
    no-underline
  `}
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;

  &:hover,
  &:focus {
    ${tw`
      border-white
    `}
  }
`

const MenuItemStyle = css`
  ${tw`
    border-0 border-b border-solid border-transparent
    font-montserrat
    text-black
    text-sm
    leading-tight
    no-underline
  `}
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;

  &:hover,
  &:focus {
    ${tw`
      border-black
    `}
  }
`

const MenuItem = ({children, to, type, internal, target, intl, ...props}) => {
  if (internal) {
    return (
      <Link
        to={fixInternalLinks(to, intl.locale)}
        {...props}
        css={(type === 'float') ? MenuItemFloatStyle : MenuItemStyle}
      >
        {children}
      </Link>
    )
  }

  return (
    <a
      href={to}
      {...props}
      css={(type === 'float') ? MenuItemFloatStyle : MenuItemStyle}
      target={target}
      rel="noopener noreferrer"
      onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: (intl.locale === 'en') ? 'Careers_External_Link' : 'Careers_External_Link_FR'}})}
    >
      {children}
    </a>
  )
}

MenuItem.propTypes = {
  // Either blue or outlined
  to: PropTypes.string.isRequired,
  // Is the menu item floating on images
  type: PropTypes.string,
  // Any additional menu elements (used for homepage)
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  internal: PropTypes.bool
}

MenuItem.defaultProps = {
  type: '',
  children: null,
  internal: false
}

export default injectIntl(MenuItem)
