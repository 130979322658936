/* eslint-disable */
import React, {Component} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core'
import {injectIntl, FormattedMessage} from 'react-intl';
import Imgix from "react-imgix"

import Icon from '../Icon'
import { Flex, Box } from '../../common/Grid'
import Button from '../../common/Button'
import sendEvent from '../../../helpers/sendAnalytics'
import fixImageUrl from '../../../helpers/fixImageUrl'

const ToggleCSS = css`
  ${tw`fixed pin-b cursor-pointer font-montserrat text-lg text-white px-8 py-6 inline-block uppercase`}
  background-color: #121314;
  padding-right: 200px;
  letter-spacing: 0;
  z-index: 500;
  left: 200px;
  font-weight: bold;
  svg {
    position: absolute;
    right: 2rem;
  }
  @media screen and (max-width: 64em) {
    left: 0px;
    bottom: -1px;
    right: 0px;
    width: 100%;
    padding-right: 80px;

    svg {
      top: 50%;
      margin-top: -12px;
    }
  }
`

const Toggle = styled.div`
  ${ToggleCSS}
  transition: 0.2s;
  transform: translateY(${props => props.open ? '-230px' : '0px'});
  svg {
    transition: 0.2s;
    transform: rotate(${props => !props.open ? '180deg' : '0deg'});
  }
`

const ToggleWrapper = styled.div`
  ${tw`bg-white fixed`}
  height: 230px;
  bottom: 0;
  width: 100%;
  background-color: #121314;
  transition: 0.2s;
  z-index: 99;
  display:flex;
  align-items:flex-end;
  transform: translateY(${props => props.open ? '0px' : '230px'})
`

const OffersContainer = styled.div`
  display:flex;
  align-items:flex-end;
  width: 100%;
  max-width: calc(100vw - 482px);
  margin: 0 auto;
  position: relative;
  height: 100%;
  @media(max-width: 919px){
    max-width: 100%;
    margin: 0;
  }

  img {
    max-width: 200px;
    width: 100%;
  }

  & > div {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 40em) {
    img {
      margin-left: auto;
      max-width: 175px;
    }

    button {
      padding: 15px 20px;

      :lang(fr) {
        padding: 10px 15px;
      }
    }
  }
`

const SliderContainer = styled.div`
  display: block;
  width: 100%;
  height: 230px;
`

const BiancaHeader = styled.h2`
  ${tw`font-montserrat font-light text-white text-4xl m-0 mb-6`}
  max-width: 600px;

  @media screen and (max-width: 40em) {
    ${tw`text-lg`}

    :lang(fr) {
      margin-bottom: 1rem;
    }
  }
`

class Offers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOffers: true
    };
    this.toggleOffers = this.toggleOffers.bind(this);
  }

  componentDidMount(){
    let hideOffers = sessionStorage.getItem('hide_offers')
    if(hideOffers){
      this.setState({showOffers: false})
    } else {
      sessionStorage.setItem('hide_offers',true)
    }
  }

  toggleOffers(){
    this.setState(prevState => ({
      showOffers: !prevState.showOffers
    }));
  }

  render() {
    const { intl } = this.props;
    const { showOffers } = this.state
    const settings = {
      settings: "unslick",
      responsive: [
        {
          breakpoint: 919,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay:false,
            autoplaySpeed: 3000,
          }
        }
      ]
    };

    let image = fixImageUrl('/globalassets/promotion/bianca/bianca-spotify2.png');
    if (intl.locale === 'fr') {
      image = fixImageUrl('/globalassets/promotion/bianca/bianca-spotify-fr2.png');
    }

    return (
      <div className="hide-me">
        <Toggle open={showOffers} onClick={this.toggleOffers}>
          <FormattedMessage id="offers" />
          <Icon icon="chevron" down strokeWidth={4} size={24} stroke="#C8122F" />
        </Toggle>
        <ToggleWrapper open={showOffers}>
            <OffersContainer>
              <Flex
                alignItems="center"
                flexWrap="wrap"
                flexDirection={['row', 'row', 'row', 'column']}
                justifyContent="center"
                mx={-3}
              >
                <Box
                  width={[
                    1/2,
                    1/2,
                    1/2,
                    "210px"
                  ]}
                  px={3}
                >
                  <Imgix
                    src={image}
                    width={230}
                    alt=""
                  />
                </Box>
                <Box
                  width={[
                    1/2,
                    1/2,
                    1/2,
                    "100%"
                  ]}
                  px={3}
                >
                  <BiancaHeader>
                    <FormattedMessage id="offerstext" />
                  </BiancaHeader>
                  <Button modification="red" to="https://open.spotify.com/playlist/3pZVkvIeDt5gkQU0J7Pw4B" label="BiancaSpotify" external>
                    <FormattedMessage id="offersbutton" />
                  </Button>
                </Box>
              </Flex>
            </OffersContainer>
        </ToggleWrapper>
      </div>
    )
  }
}

export default injectIntl(Offers)
